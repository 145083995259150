table {
  width: 100%;
}
.containerDashboardP tr th {
  margin-top: 3em;
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.containerDashboardP th,
.containerDashboardP td {
  padding: 12px 15px;
}

.containerDashboardP tr td {
  border-bottom: 1px solid #dddddd;
}

.containerDashboardP h1 {
  text-align: center;
}

@media (max-width: 768px) {
  .mobileResponsive {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}

.image-wrapper, .butonatNeQender {
  display: flex;
  justify-content: center;
  align-items: center;
}
.titleP{
  margin-bottom: 0.8em;
}

.butonatNeQender{
    flex-wrap: wrap;
    gap: 0.5em;
    margin-top: 1.5em;
}

.containerCheckoutForma{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.containerDashboardPP tr th {
  margin-top: 3em;
  background-color: #0f243b;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid #b8b5b5;
  opacity: 0.7;
}



.containerDashboardPP th,
.containerDashboardPP td {
  padding: 12px 15px;
}

.containerDashboardPP tr td {
  border-bottom: 1px solid #dddddd;
}

@media (max-width: 767px) {
  .col-3{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .image-wrapper{
    width: 25%;
  }

  .butonatNeQender{
    margin-top: 3em;
  }
}

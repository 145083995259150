
.forms {
  display: flex;
  justify-content: space-between;
  flex-basis: 120em;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 30px;
  width: fit-content;
  margin: auto;
  margin-top: 5em;
  margin-bottom: 7em;
  -webkit-box-shadow: 0 0 20px #ddd;
  box-shadow: 0 0 20px #ddd;
  padding: 3em 2em;
  
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  width: 100%;
  color: rgb(135, 132, 132);
}

.formTitle {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 25pt;
}

.forms table {
  color: white;
}

.inputt {
  border: 0;
  border-bottom: 1.4px solid #d3d3d3;
  border-radius: 0;
  color: #ddd;
  background-color: inherit;
  width: max(27em, 20vw);  
  
}


.button {
  border: none;
  background-color: whitesmoke;
  color: white;
  background: rgb(61, 101, 160);
  background: #01df93;
  padding: 1em 2em;
  width: 100%;
  border-radius: 50px;
  margin-top: 1em;
}

.button:hover {
  background: #01df93;
  color: white;
  cursor: pointer;
}

@media (max-width: 480px) {
  .forms {
    margin-top: 3em;
    margin-bottom: 0;
  }

  .inputt {
    width: 15em;
  }
}

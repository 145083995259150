.modal {
  margin-top: 7em;
}

.modalEditShto{
  margin-top: 0;
}

.imgFshij {
  width: 50%;
  aspect-ratio: 3/2;
  object-fit: contain;
  margin-left: 1em;
}

.Butoni {
  background-color: #009879;
  border: none;
  float: right;
  margin-right: 1em;
}

.Butoni:hover {
  background-color: #01df93;
}

.ikonat {
  color: white;
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 10em;
  margin-bottom: 10em;
}

.table{
  width: 100%;
}



.tableBig {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  /* width: 98%; */
  max-width: 100%;
  margin: 0 auto;
}

.containerDashboardP tr th {
  margin-top: 3em;
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.containerDashboardP th,
.containerDashboardP td {
  padding: 12px 15px;
  
}

.containerDashboardP tr td {
  border-bottom: 1px solid #dddddd;
}

.containerDashboardP h1 {
  text-align: center;
}

@media screen and (max-width: 767px) {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 90%;
    margin: 0 auto;
  }
  .tableBig{
    max-width: 90%;
  }

  .modalEditShto{
    margin-top: 6em;
  }
}

.cart-page {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-right: 30em;
}

a{
  color: #000;
}

.produkti-cart {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 25px;
  font-size: 1em;
  padding: 3em;
  border-radius: 30px;
  box-shadow: 0 0 10px #d3d3d3;
  width: 100%;
}

.produkti-cart:last-child {
  margin-bottom: 3.5em;
}

.foto img {
  width: 80%;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.teDhenatProduktit {
  width: 100%;
}

.d-title {
  margin-bottom: 1em;
}
.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.details {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin: 25px;
  font-size: 1em;
  padding: 3em 4em;
  border-radius: 30px;
  box-shadow: 0 0 10px #d3d3d3;
  flex-direction: column;
  position: fixed;
  top: 80px; 
  right: 3em;
}

.details p, .details h4 {
  border-bottom: solid 1px #dddddd;
}

.shporta-zbrazet {
  margin-top: 3em;
  margin-bottom: 3em;
}

.cart-button {
  border: none;
  box-shadow: 0 0 10px #d3d3d3;
  background-color: #01df93;
  color: white;
  padding: 10px;
  width: 100%;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  /* margin-bottom: 2em; */
}

.cart-button:hover {
  background-color: white;
  color: #01df93;
}

.funksionet {
  display: flex;
  flex-direction: column;
}

.funksionet input {
  width: 3em;
}
.sasia {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.sasia .decrement {
  margin-left: 1em;
}

.sasiaInputi {
  display: flex;
  align-items: center;
}

.sasiaInputi button {
  background-color: #f5f5f5;
  border: none;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 10px;
  cursor: pointer;
}

.sasiaInputi button:hover {
  background-color: #ddd;
}

.sasiaInputi input[type="number"] {
  width: 50px;
  height: 30px;
  font-size: 16px;
  text-align: center;
  margin: 0 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.promo-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.3em;
  margin-top: 0.3em;
}

#promo-input {
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 3px;
  margin-right: 10px;
  width: 200px;
}

.promo-button {
  background-color: #f5f5f5;
  color: #000;
  border: none;
  border-radius: 25%;
  width: 32.5px;
  height: 32.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.promo-button:hover {
  background-color: #ddd;
}

.zbritja{
  margin-top: -1em;
}

.modalEditoDhenat{
  margin-top: 1em;
}

@media (max-width: 1024px) {
  .cart-page {
    flex-wrap: wrap;
    margin-right: 0;
  }
  .produkti-cart {
    width: 90%;
  }
  .info {
    width: 90%;
    flex-direction: column;
  }
  .foto img {
    width: 100%;
  }
  .foto {
    margin-bottom: 1em;
  }
  .details {
    position: relative;
    top: 0;
    right: 0;
  }
  .cart-title::before {
    content: "Shporta Juaj";
    display: block;
    text-align: center;
    color: black;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
}

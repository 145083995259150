table {
  width: 100%;
}
.containerDashboardP tr th {
  margin-top: 3em;
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.containerDashboardP th,
.containerDashboardP td {
  padding: 12px 15px;
}

.containerDashboardP tr td {
  border-bottom: 1px solid #dddddd;
}

.containerDashboardP h1{
  text-align: center;
}

.cardStatisitkat{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 1em;
  gap: 1em;
}

.KartaStatistikave{
  width: 17.5rem;
}

.TekstiStatistika{
  font-weight: bold;
  font-size: 25pt;
}

@media (max-width: 767px) {
  .KartaStatistikave{
    width: 30rem;
  }

  .cardStatisitkat{
    flex-wrap: wrap;
  }
}

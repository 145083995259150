.fatura *, .PjesaNenshkrimeve * {
  /* styles specific to elements inside .fatura */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18pt;
}

.fatura, .PjesaNenshkrimeve {
  margin: 2em;
}

.header {
  display: flex;
  width: 100%;
}

.teDhenatKompanis {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.teDhenatKompanis img {
  width: 250px;
  height: auto;
  margin-bottom: 1em;
}
.teDhenatKlientit {
  width: 100%;
  margin-top: 3em;
  margin-bottom: 3em;
}

.data {
  text-align: end;
  width: 100%;
}

.tabelaETeDhenaveProduktit {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.tabelaETeDhenaveProduktit table {
  width: 100%;
  text-align: center;
}

.tabelaETeDhenaveProduktit th,
td {
  padding: 12px 15px;
}

.containerFatura {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.detajetDorezimit {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.detajetPoresis {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
}

.fatura h1, .PjesaNenshkrimeve h1 {
  font-size: 30pt;
}

.fatura h2, .PjesaNenshkrimeve h2 {
  font-size: 25pt;
}

.fatura h3, .PjesaNenshkrimeve h3 {
  font-size: 20pt;
}

.nenshkrimet {
  display: flex;
  justify-content: space-between;
  margin-top: 18em;
}

.nenshkrimet2Faqe {
  display: flex;
  justify-content: space-between;
  margin-top: 30em;
}

.nenshkrimi {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nenshkrimi span {
  margin-top: 0.5em;
  font-size: 14px;
  font-weight: bold;
}

.nenshkrimi span:first-child {
  margin-bottom: 0.5em;
}

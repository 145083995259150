table {
  width: 100%;
  /* white-space: nowrap; */
  
}

.title{
  margin-top: 1em;
  
}

.containerDashboardP tr th {
  margin-top: 3em;
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.containerDashboardP th,
.containerDashboardP td {
  padding: 12px 15px;
}

.containerDashboardP tr td {
  border-bottom: 1px solid #dddddd;
}


.edito {
  padding: 0.4em;
  background-color: #45c263;
  border: none;
  border-radius: 2px;
}

.edito,
.edito a {
  color: white;
}

.fshij {
  padding: 0.4em;
  background-color: #e33437;
  border: none;
  border-radius: 2px;
}

.fshij,
.fshij a {
  color: white;
}

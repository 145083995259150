.dashboard {
  overflow-x: hidden;
  width: 100%;
}
.containerDashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  flex-direction: column;
  gap: 2em;
  flex-wrap: wrap;
}
.containerDashboard tr th {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.containerDashboard th,
.containerDashboard td {
  padding: 12px 15px;
}

.containerDashboard tr td {
  border-bottom: 1px solid #dddddd;
}

.titulliPershkrim {
  display: flex;
  flex-direction: column;
  color: white;
  background-color:#009879; 
  width: 25%;
  text-align: center;
  margin-top: 1.6em;
  padding: 14px;
}

.info{
  display: flex;
  flex-direction: column;
  color: grey; 
  text-align: left;
  margin-right: auto;
  margin-left: 3.8em;
  
}

.button {
  border: none;
  box-shadow: 0 0 10px #d3d3d3;
  background-color: white;
  color: black;
  padding: 10px;
  width: 12em;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
}

.button:hover {
  background-color: #01df93;
  color: white;
}
@media (max-width: 767px) {
  .containerDashboard table {
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
  }
  table {
    display: block;
  }
  .containerDashboard {
    flex-direction: unset;
  }

  .butonatDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1em;
  }
}
